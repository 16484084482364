import { RoundedTitle } from "components/shared/panda/RoundedTitle"
import PortableTitle from "components/shared/PortableTitle"
import { getHref, useSizing } from "lib/helpers"
import { urlForImage } from "lib/sanity.image"
import Link from "next/link"
import { VerticalMediaText1 } from "schemas"
import { Container, styled, VStack } from "styled-system/jsx"
import { hstack } from "styled-system/patterns"
import { token } from "styled-system/tokens"

type Props = VerticalMediaText1

function Columns({
  title,
  subtitle,
  body,
  cta,
  image,

  sizingTop,
  sizingBottom,
  sizingTopMobile,
  sizingBottomMobile,
}: Props) {
  return (
    <styled.div
      className="verticalMediaText1"
      style={
        {
          "--pt": useSizing(sizingTop),
          "--pb": useSizing(sizingBottom),
          "--ptm": useSizing(sizingTopMobile),
          "--pbm": useSizing(sizingBottomMobile),
        } as React.CSSProperties
      }
      css={{
        pt: "var(--ptm, var(--pt))",
        pb: "var(--pbm, var(--pb))",
        color: "white",
        textAlign: "center",
        background: "linear-gradient(180deg, #1800D9 -22.65%, #09004E 67.42%)",

        lg: {
          pt: "var(--pt)",
          pb: "var(--pb)",
        },
      }}>
      <Container>
        <VStack gap={0}>
          <RoundedTitle as="h4" color="white" mb="24px" responsive>
            {subtitle}
          </RoundedTitle>
          <PortableTitle
            as="h3"
            altColor={token("colors.secondary.1")}
            css={{
              fontFamily: "heading",
              fontSize: "26px",
              fontWeight: 700,
              lineHeight: "126%",
              mb: "16px",

              lg: {
                fontSize: "46px",
              },
            }}>
            {title}
          </PortableTitle>
          <styled.p
            css={{
              color: "#DEDEDE",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "133%",
              mb: "40px",

              lg: {
                fontSize: "22px",
                mb: "48px",
              },
            }}>
            {body}
          </styled.p>
          {cta && (
            <styled.a
              as={Link}
              href={getHref(cta)}
              className={hstack({
                mb: 16,
                gap: 2,
                mx: "auto",
                color: "secondary.1",
                fontWeight: 600,
                lineHeight: "150%",
                textTransform: "uppercase",
              })}>
              {cta.text}
              <Arrow />
            </styled.a>
          )}
          {image?.asset && (
            <styled.img alt={image.alt || ""} src={urlForImage(image).url()} />
          )}
        </VStack>
      </Container>
    </styled.div>
  )
}

const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="11"
    viewBox="0 0 8 11"
    fill="none">
    <path
      d="M2.10982 0.14975C1.79141 0.150017 1.48026 0.244846 1.21583 0.422209C0.951402 0.599572 0.745608 0.851476 0.624554 1.14597C0.5035 1.44046 0.47264 1.76428 0.535888 2.07633C0.599137 2.38839 0.753644 2.67463 0.979811 2.89875L3.0102 4.90788C3.08847 4.98547 3.15059 5.07778 3.19298 5.1795C3.23538 5.28122 3.2572 5.39032 3.2572 5.50052C3.2572 5.61072 3.23538 5.71983 3.19298 5.82155C3.15059 5.92327 3.08847 6.01558 3.0102 6.09317L0.979811 8.1023C-0.0406999 9.11111 0.674721 10.8502 2.10982 10.8502C2.53331 10.8504 2.93978 10.6835 3.24088 10.3857L7.02315 6.64169C7.17421 6.4924 7.29414 6.31461 7.376 6.11863C7.45785 5.92265 7.5 5.71238 7.5 5.49999C7.5 5.28761 7.45785 5.07733 7.376 4.88135C7.29414 4.68537 7.17421 4.50759 7.02315 4.3583L3.24088 0.614295C2.93978 0.316485 2.53331 0.149542 2.10982 0.14975Z"
      fill="url(#paint0_linear_1_253)"
    />
    <path
      d="M2.10982 0.14975C1.79141 0.150017 1.48026 0.244846 1.21583 0.422209C0.951402 0.599572 0.745608 0.851476 0.624554 1.14597C0.5035 1.44046 0.47264 1.76428 0.535888 2.07633C0.599137 2.38839 0.753644 2.67463 0.979811 2.89875L3.0102 4.90788C3.08847 4.98547 3.15059 5.07778 3.19298 5.1795C3.23538 5.28122 3.2572 5.39032 3.2572 5.50052C3.2572 5.61072 3.23538 5.71983 3.19298 5.82155C3.15059 5.92327 3.08847 6.01558 3.0102 6.09317L0.979811 8.1023C-0.0406999 9.11111 0.674721 10.8502 2.10982 10.8502C2.53331 10.8504 2.93978 10.6835 3.24088 10.3857L7.02315 6.64169C7.17421 6.4924 7.29414 6.31461 7.376 6.11863C7.45785 5.92265 7.5 5.71238 7.5 5.49999C7.5 5.28761 7.45785 5.07733 7.376 4.88135C7.29414 4.68537 7.17421 4.50759 7.02315 4.3583L3.24088 0.614295C2.93978 0.316485 2.53331 0.149542 2.10982 0.14975Z"
      fill="#0CDCB7"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_253"
        x1="0.499714"
        y1="5.49999"
        x2="7.5"
        y2="5.49997"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#8C60FA" />
        <stop offset="0.206916" stopColor="#8E69FB" />
        <stop offset="0.4388" stopColor="#9482FC" />
        <stop offset="0.891867" stopColor="#9EAAFF" />
        <stop offset="0.997108" stopColor="#9FAFFF" />
      </linearGradient>
    </defs>
  </svg>
)

export default Columns
export type { Props as VerticalMediaText1Props }
